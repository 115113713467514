/** @format */

@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;
@use "../../styles/resets" as *;
@use "../../styles/breakpoints" as *;

.home-container {
}

.skills-container {
  background-color: $background-light;
}

.skills {
  margin: 0 1rem;
  max-width: 1020px;
  padding: 0 1rem 4rem 1rem;
  @include tablet {
    padding: 0 3.5rem 4rem 3.5rem;
    margin: 0 auto;
  }
  @include desktop {
    padding: 2rem 3.5rem 6rem 3.5rem;
  }
  &__title {
    @include m-t-d-h2;
    padding: 4rem 0 1rem 0;
    color: $text-dark;
  }
  &__images {
    @include flex(row, space-around, center);
    flex-wrap: wrap;
    padding: 1rem 0 0 0;
    // height: 20rem;
  }
  &__icon {
    width: 150px;
    height: 100px;
    margin: 1rem 0;
    // padding: 1rem 0.5rem;
    // padding: 1rem 0;
    &-margin {
      margin: 0;
    }
    @include tablet {
      margin: 1rem 0.75rem;
    }
  }
  &__micro {
    width: 150px;
    height: 100px;
  }
}
