/** @format */

@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;
@use "../../styles/resets" as *;
@use "../../styles/breakpoints" as *;

.contact-container {
  background-color: $background-light;
}

.contact {
  max-width: 1440px;
  margin: 0 1rem;
  padding: 0 1rem 4rem 1rem;
  @include tablet {
    padding: 0 3.5rem 4rem 3.5rem;
    margin: 0 auto;
  }
  &__title {
    @include m-t-d-h3;
    padding: 4rem 0 1rem 0;
    color: $text-dark;
  }
  &__description {
    @include m-t-d-text-big;
    color: $text-dark;
    padding: 1rem 0 0 0;
  }
  &__icons {
    @include flex(row, space-evenly, center);
    // padding: 1.5rem 0;
    margin: 1.5rem 0;
    @include tablet {
      // padding: 1.5rem 2rem;
      margin: 1.5rem 8rem;
    }
    @include desktop {
      margin: 1.5rem 12rem;
    }
  }
  &__icons-container {
    @include flex(row, center, center);
    width: 100%;
    background-color: $background-light;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  &__email {
    text-decoration: none;
    list-style: none;
  }
  &__linkedin {
    width: 30px;
  }
  &__github {
    width: 30px;
  }
}

.button {
  // width: 100%;
  @include flex(row, center, center);
  @include m-t-d-text-big;
  background-color: $background-light;
  color: $text-light-accent;
  border: 2px solid $text-dark-accent;
  font-size: 18px;
  margin: 2rem auto;
  padding: 0.25rem 2rem;
  border-radius: 0.25rem;
  text-decoration: none;
}
