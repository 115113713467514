/** @format */

$text-dark: #391400;
$text-dark-accent: #ef6d58;
$text-dark-grey: rgba(57, 20, 0, 0.64);
$text-light: #ffffff;
$text-light-accent: #ef6d58;
$text-light-grey: rgba(255, 255, 255, 0.64);

$background-dark: rgb(40, 41, 62);
$background-light: #fdf0e9;
$background-button: rgb(181, 80, 248);
