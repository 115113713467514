/** @format */

$mobile-min-width: 320px;
$mobile-max-width: 425px;
$medium-min-width: 426px;
$medium-max-width: 767px;
$tablet-min-width: 768px;
$tablet-max-width: 1024px;
$desktop-small-min-width: 1025px;
$desktop-small-max-width: 1279px;
$desktop-min-width: 1280px;
$desktop-max-width: 1440px;

/* media query for mobile */
@mixin small {
  @media only screen and (min-width: $mobile-min-width) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: $medium-min-width) {
    @content;
  }
}

/* media query for tablet */
@mixin tablet {
  @media only screen and (min-width: $tablet-min-width) {
    @content;
  }
}

@mixin desktop-small {
  @media only screen and (min-width: $desktop-small-min-width) {
    @content;
  }
}

/* media query for landscape, desktop pixel density desktops */
@mixin desktop {
  @media only screen and (min-width: $desktop-min-width) {
    @content;
  }
}

/* media query for high pixel density desktops */
// @mixin desktop-large {
//   @media only screen and (min-width: 1440px) {
//     @content;
//   }
// }
