/** @format */

@use "./breakpoints" as *;

html,
body
// input
{
  font-family: Epilogue, "serif";
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  min-width: 375px;
  // max-width: 1280px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
h1,
h2,
h3,
h4,
h5,
p,
ul,
img,
label,
input {
  margin: 0;
  padding: 0;
}

li,
button {
  cursor: pointer;
}

textarea {
  resize: none;
  // box-sizing: border-box;
  // overflow: auto;
}
