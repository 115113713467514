/** @format */
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/breakpoints" as *;
@use "../../styles/resets" as *;

.hero-container {
  background-color: $background-dark;
  padding: 4rem 0;
  // height: 100vh;
}

.hero {
  @include flex(column, center, flex-start);
  max-width: 1440px;
  padding: 2rem 1rem;
  margin: 0 1rem;
  // height: 100vh;
  @include tablet {
    padding: 0 3.5rem;
    margin: 0 auto;
  }
  @include desktop-small {
    @include flex(row, center, center);
    padding: 0 4rem 3.5rem 4rem;
  }
  @include desktop {
    padding: 2rem 3.5rem 6rem 3.5rem;
  }
  &__picture {
    @include flex(column, center, center);
    width: 100%;
    margin: 0 auto 4rem auto;
    padding: 1rem 0 0 0;
    @include tablet {
      width: 100%;
    }
    @include desktop-small {
      width: 60%;
      padding: 0 2rem 0 0;
    }
    @include desktop {
      width: 50%;
      padding: 0 2rem 0 0;
    }
  }
  &__img {
    width: 100%;
    @include tablet {
      width: 80%;
    }
    @include desktop-small {
      width: 100%;
    }
  }
  &__text {
    padding: 0 0 1rem 0;
    @include desktop-small {
      padding: 0 0 0 2rem;
    }
  }
  &__name {
    @include m-t-d-h5;
    padding: 2rem 0 0.5rem 0;
    color: $text-light-accent;
  }
  &__title {
    @include m-t-d-h3;
    padding: 0.5rem 0 0.5rem 0;
    color: $text-light;
    width: 100%;
    @include desktop-small {
      @include m-t-d-h3;
    }
  }
}
